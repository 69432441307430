
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'CarManageList'
})
export default class extends Vue {
  private activeName ='base'

  created (): void {
    this.$store.dispatch('getCarTypeList')
    this.setActive()
  }

  setActive () {
    const url = this.$route.path
    const start = url.lastIndexOf('/')
    this.activeName = url.substring(start + 1)
  }

  handleClick () {
    this.$router.replace({ path: '/carManageList/' + this.activeName })
  }
}
